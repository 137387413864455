import { addMinutes, format } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import ja from 'date-fns/locale/ja'

// ブラウザのタイムゾーンに合わせて日付をフォーマットする
export const formatDate = (date: Date | number, dateFormat: string) => {
  return format(date, dateFormat, { locale: ja })
}

// 強制的にJSTにしてフォーマットする
// GraphQL APIから返ってくる日付（not日時）系のデータはGraphQL.DateTime型に変換されているため、UTCでのISO8601フォーマットの文字列で送られてくる。
// それをそれぞれのブラウザタイムゾーンでフォーマットすると日付がズレてしまうので、それに対応する場合に使用する。
export const formatUtcDateStringInJST = (utcDate: string | Date, dateFormat: string): string => {
  const jstDate = utcToZonedTime(typeof utcDate === 'string' ? new Date(utcDate) : utcDate, 'Asia/Tokyo')
  return formatDate(jstDate, dateFormat)
}

// UTC-7:00(アメリカとか)のTZでnew Date('2022-07-28')すると2022-07-27 17:00になってしまい
// 日付が意図したものと1日ずれるので強制的にUTCでの日時に変換する
export const parseDateStringAsUtc = (dateString: string): Date => {
  const date = new Date(dateString)
  // 例えばTZ=JSTだと+9hなので、-540が返ってくる
  const offsetMinutes = date.getTimezoneOffset()
  return addMinutes(date, offsetMinutes)
}
