import { Loading } from 'components/ui/loading'
import { useAuthState } from 'context/auth'
import { useMyselfState } from 'context/myself'
import { useShowErrorMessageIfNeed } from 'hooks/useShowErrorMessageIfNeed'
import { useRouter } from 'next/router'
import { FC, ReactNode, useEffect, useState } from 'react'
import { Routes } from 'routes'

export type LoginRequirements =
  | 'authSignedIn' // FirebaseAuthでログインしている
  | 'registrationCreated' // 会社登録申請は作成済み
  | 'registrationApproved' // 申請が承認済み

const RequirementLevel: { [key in LoginRequirements]: number } = {
  authSignedIn: 0,
  registrationCreated: 10,
  registrationApproved: 20,
}

type Props = {
  requirements: LoginRequirements
  children: ReactNode
}

export const LoginRequired: FC<Props> = ({ requirements, children }) => {
  const { firebaseUser } = useAuthState()
  const { initialized, loading, myself, error } = useMyselfState()
  const [shouldRender, setShouldRender] = useState(false)
  const router = useRouter()
  useShowErrorMessageIfNeed(error)

  useEffect(() => {
    if (!initialized || loading) return

    if (!firebaseUser || !myself) {
      router.replace(Routes.lp())
      return
    }

    const level = RequirementLevel[requirements]

    if (level < RequirementLevel.registrationCreated) {
      setShouldRender(true)
      return
    }
    if (!myself?.latestAgencyRegistration && !myself?.employee) {
      router.replace(Routes.accountSetting())
      return
    }

    if (level < RequirementLevel.registrationApproved) {
      setShouldRender(true)
      return
    }
    if (!myself.employee) {
      router.replace(Routes.accountSetting())
      return
    }

    setShouldRender(true)
  }, [requirements, initialized, firebaseUser, myself, loading])

  if (!shouldRender) return <Loading />

  return <>{children}</>
}
