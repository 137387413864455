import { ApolloError } from '@apollo/client'
import { publicEnv } from 'env'
import { FirebaseError } from 'firebase/app'
import { GraphQLError } from 'graphql'

interface BaseProcessedError {
  originalError: Error
  type: 'apollo' | 'firebase' | 'unknown'
}

export interface ProcessedUnknownError extends BaseProcessedError {
  type: 'unknown'
  originalError: Error
}

export interface ProcessedApolloErrorBase extends BaseProcessedError {
  type: 'apollo'
  code: Exclude<ApolloErrorCode, 'INVALID_OEMBED'>
}

export interface ProcessedFirebaseError extends BaseProcessedError {
  type: 'firebase'
  code: FirebaseErrorCode
}

export type ProcessedApolloError = ProcessedApolloErrorBase
export type ProcessedError = ProcessedUnknownError | ProcessedApolloError | ProcessedFirebaseError

export type ApolloCommonErrorCode =
  | 'UNAUTHENTICATED'
  | 'FORBIDDEN'
  | 'INVALID_CONDITION'
  | 'NOT_FOUND'
  | 'UNKNOWN_NETWORK_ERROR'
  | 'INVALID_OEMBED'
  | 'UNEXPECTED'
// mutationでしか発生しないもの
export type ApolloMutationErrorCode = 'BAD_USER_INPUT' | 'FREEZED'
// ProcessedApolloOembedError の際に付与されている追加エラーコード
export type OembedErrorCode = 'HIDDEN_CONTENT' | 'NOT_ALLOWED' | 'NOT_FOUND'

export type ApolloErrorCode = ApolloCommonErrorCode | ApolloMutationErrorCode

export type FirebaseErrorCode =
  | 'INVALID_EMAIL'
  | 'WRONG_EMAIL_OR_PASSWORD'
  | 'INVALID_ACTION_CODE'
  | 'WEAK_PASSWORD'
  | 'EMAIL_ALREADY_IN_USE'
  | 'TOO_MANY_ATTEMPTS'
  | 'CREDENTIAL_TOO_OLD_LOGIN_AGAIN'
  | 'UNEXPECTED'

export const processError = (error: unknown): ProcessedError => {
  if (error instanceof ApolloError) {
    return processApolloError(error)
  }

  if (error instanceof FirebaseError) {
    return processFirebaseError(error)
  }

  // Apollo以外のエラーをハンドリングする場合はここでハンドリング

  return { originalError: error, type: 'unknown' } as ProcessedUnknownError
}

export const processApolloError = (error: ApolloError): ProcessedApolloError => {
  if (error.graphQLErrors.length > 0) {
    return processGraphQLErrors(error.graphQLErrors as GraphQLError[], error)
  }

  if (error.networkError) {
    const netError = error.networkError as any
    // BAD_USER_INPUTの場合、ApolloServerはステータスコードを400にするせいでresultの中にGraphQLError[]が入っている
    if (netError.result && netError.result.errors && Array.isArray(netError.result.errors)) {
      const gqlErrors = netError.result.errors as GraphQLError[]
      return processGraphQLErrors(gqlErrors, error)
    }

    return { code: 'UNKNOWN_NETWORK_ERROR', originalError: error, type: 'apollo' }
  }

  if (publicEnv.env !== 'prod') {
    // eslint-disable-next-line no-console
    console.warn(error)
  }

  return { code: 'UNEXPECTED', originalError: error, type: 'apollo' }
}

export const processGraphQLErrors = (errors: readonly GraphQLError[], originalError?: Error): ProcessedApolloError => {
  const gqlError = errors[0]
  let code: ApolloErrorCode = 'UNEXPECTED'
  switch (gqlError.extensions?.code) {
    case 'BAD_USER_INPUT':
      code = 'BAD_USER_INPUT'
      break
    case 'NOT_FOUND':
      code = 'NOT_FOUND'
      break
    case 'UNAUTHENTICATED':
      code = 'UNAUTHENTICATED'
      break
    case 'FORBIDDEN':
      code = 'FORBIDDEN'
      break
    case 'INVALID_CONDITION':
      code = 'INVALID_CONDITION'
      break
    case 'FREEZED':
      code = 'FREEZED'
      break
    default:
      code = 'UNEXPECTED'
      if (publicEnv.env !== 'prod') {
        // eslint-disable-next-line no-console
        console.warn(gqlError)
      }

      break
  }

  return { code, originalError: originalError ?? gqlError, type: 'apollo' }
}

export const processFirebaseError = (error: FirebaseError): ProcessedFirebaseError => {
  let code: FirebaseErrorCode
  switch (error.code) {
    case 'auth/invalid-email':
      code = 'INVALID_EMAIL'
      break
    case 'auth/email-already-in-use':
      code = 'EMAIL_ALREADY_IN_USE'
      break
    case 'auth/user-not-found':
    case 'auth/wrong-password':
      code = 'WRONG_EMAIL_OR_PASSWORD'
      break
    case 'auth/weak-password':
      code = 'WEAK_PASSWORD'
      break
    case 'auth/invalid-action-code':
      code = 'INVALID_ACTION_CODE'
      break
    case 'auth/too-many-requests':
      code = 'TOO_MANY_ATTEMPTS'
      break
    case 'auth/requires-recent-login':
      code = 'CREDENTIAL_TOO_OLD_LOGIN_AGAIN'
      break
    default:
      if (publicEnv.env !== 'prod') {
        // eslint-disable-next-line no-console
        console.warn(error)
      }

      code = 'UNEXPECTED'
      break
  }

  return { type: 'firebase', code, originalError: error }
}
