import { ProcessedApolloError, ProcessedError, ProcessedFirebaseError } from './processError'

export const buildErrorMessage = (error: ProcessedError): string => {
  switch (error.type) {
    case 'unknown':
      return 'システムエラーが発生しました'
    case 'apollo':
      return buildApolloErrorMessage(error)
    case 'firebase':
      return buildFirebaseErrorMessage(error)
  }
}

export const buildApolloErrorMessage = (error: ProcessedApolloError): string => {
  switch (error.code) {
    case 'BAD_USER_INPUT':
      return '入力された値が正しく有りません'
    case 'FORBIDDEN':
      return '権限がありません'
    case 'INVALID_CONDITION':
      return 'データが正しくない可能性があります。画面を再読み込み後に再度お試しください。'
    case 'NOT_FOUND':
      return '該当するデータが見つかりませんでした'
    case 'UNAUTHENTICATED':
      return 'ログインが必要です'
    case 'FREEZED':
      return '取引が停止されているためこの操作はできません'
    case 'UNEXPECTED':
    case 'UNKNOWN_NETWORK_ERROR':
      return 'システムエラーが発生しました'
  }
}

export const buildFirebaseErrorMessage = (error: ProcessedFirebaseError): string => {
  switch (error.code) {
    case 'INVALID_EMAIL':
      return 'メールアドレスの形式が正しくありません'
    case 'WRONG_EMAIL_OR_PASSWORD':
      return 'メールアドレスまたはパスワードが正しくありません'
    case 'INVALID_ACTION_CODE':
      return 'このリンクは有効期限切れまたは使用済みです'
    case 'WEAK_PASSWORD':
      return 'パスワードがかんたんすぎます'
    case 'EMAIL_ALREADY_IN_USE':
      return 'このメールアドレスはすでに利用されています'
    case 'TOO_MANY_ATTEMPTS':
      return 'ログイン試行回数が多すぎます。後ほどお試しください'
    case 'CREDENTIAL_TOO_OLD_LOGIN_AGAIN':
      return '画面をリロードして再度お試しください'
    case 'UNEXPECTED':
      return 'システムエラーが発生しました'
  }
}
