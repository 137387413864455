import { addDays, differenceInDays, startOfDay } from 'date-fns'
import {
  IncrementalSearchRegionTypeInput,
  IncrementalSearchResponseDataType,
  RateAmenitiesMealType,
  SearchPropertyContentCategory,
  SearchPropertyContentInputFacility,
  SearchPropertyContentRatings,
  SearchPropertyContentSortField,
  SearchPropertyContentSpotFragment,
  SortDirection,
} from 'generated/graphql'
import { formatDate, formatUtcDateStringInJST } from 'helper/dateFormat'
import { HotelOccupancy } from 'types/occupancy'

// 型

export type SearchHotelParams = SearchHotelFormInput & SearchHotelSortInput & SearchHotelFilterInput & SearchHotelPaginationInput

export type SearchHotelFormInput = {
  keyword: string
  keywordType: SearchHotelFormKeywordTypes
  coordinates?: {
    latitude: number
    longitude: number
  }
  propertyContentId?: string
  regionId?: string
  regionType?: IncrementalSearchRegionTypeInput
} & SearchHotelRoomParams

export type SearchHotelRoomParams = {
  checkin: string
  checkout: string
  occupancies: HotelOccupancy[]
}

export type SearchHotelSortInput = {
  sortField: SearchPropertyContentSortField
  sortDirection: SortDirection
}

export type SearchHotelFilterInput = {
  stars?: SearchPropertyContentRatings[]
  minPrice?: number
  maxPrice?: number
  categories?: SearchPropertyContentCategory[]
  facilities?: SearchPropertyContentInputFacility[]
  covid19Prevention?: boolean
  minGuestRatings?: number
  mealType?: RateAmenitiesMealType
  spot?: SearchPropertyContentSpotFragment
  /**
   * ホテルのID。ホテル名絞り込みで選択したものを伝えるためのフィールド。クエリパラメータからは渡ってこない。
   *
   * @type {string}
   */
  propertyContentId?: string
}

export type SearchHotelPaginationInput = {
  pageNo?: number
}

export const searchHotelFormKeywordTypes = ['area', 'hotel', 'spot', 'station', 'airport'] as const
export type SearchHotelFormKeywordTypes = (typeof searchHotelFormKeywordTypes)[number]

export const convertIncrementalSearchDataTypeToKeywordType = (type: IncrementalSearchResponseDataType): SearchHotelFormKeywordTypes => {
  switch (type) {
    case 'continent':
    case 'country':
    case 'city':
    case 'high_level_region':
    case 'multi_city_vicinity':
    case 'province_state':
      return 'area'
    case 'point_of_interest':
    case 'neighborhood':
      return 'spot'
    case 'metro_station':
    case 'train_station':
      return 'station'
    case 'airport':
      return 'airport'
    case 'property_content':
      return 'hotel'
  }
}
export const convertKeywordTypeToSearchRegionTypes = (type: SearchHotelFormKeywordTypes): IncrementalSearchRegionTypeInput[] => {
  switch (type) {
    case 'area':
      return ['city', 'high_level_region', 'multi_city_vicinity', 'province_state']
    case 'spot':
      return ['point_of_interest', 'neighborhood']
    case 'station':
      return ['metro_station', 'train_station']
    case 'airport':
      return ['airport']
    case 'hotel':
      return []
  }
}

export const regionTypes = [
  'airport',
  'city',
  'continent',
  'country',
  'high_level_region',
  'metro_station',
  'multi_city_vicinity',
  'neighborhood',
  'point_of_interest',
  'province_state',
  'train_station',
] as const
export const isRegionSpot = (type?: IncrementalSearchResponseDataType): boolean => {
  switch (type) {
    case 'airport':
    case 'metro_station':
    case 'point_of_interest':
    case 'train_station':
      return true
    default:
      return false
  }
}

// デフォルト値など

export const numOfRooms = [1, 2, 3, 4, 5, 6, 7, 8]
export const numOfAdults = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14]
export const numOfChildren = [0, 1, 2, 3, 4, 5, 6]
export const numOfChildAges = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]
export const maxNightsOfStay = 28
export const maxCheckinDaysInFuture = 365

const searchHotelParamDateInputFormat = 'yyyy-MM-dd'
export const searchHotelRoomParamDefaultData: { [key in keyof SearchHotelRoomParams]: () => SearchHotelRoomParams[key] } = {
  checkin: () => formatDate(addDays(Date.now(), 1), searchHotelParamDateInputFormat),
  checkout: () => formatDate(addDays(Date.now(), 2), searchHotelParamDateInputFormat),
  occupancies: () => createOccupancies(1),
}

export const createOccupancies = (num: number): HotelOccupancy[] => {
  if (num < 1) return []
  const defaultValue: HotelOccupancy = { adult: 2, childAges: [] }
  const array: HotelOccupancy[] = []
  for (let i = 0; i < num; i++) {
    array.push(Object.assign({}, defaultValue))
  }
  return array
}

export const searchHotelFormDefaultData: { [key in keyof SearchHotelFormInput]: () => SearchHotelFormInput[key] } = {
  keyword: () => '',
  keywordType: () => 'area',
  ...searchHotelRoomParamDefaultData,
}

export const defaultSearchHotelRoomParams = (defaultValues: Partial<SearchHotelRoomParams>): SearchHotelRoomParams => {
  const checkinOut = getValidatedCheckInOut({ checkin: defaultValues.checkin, checkout: defaultValues.checkout })
  return {
    ...checkinOut,
    occupancies: defaultValues?.occupancies ?? searchHotelRoomParamDefaultData.occupancies(),
  }
}

// checkin/outの値の組み合わせを、今日以降の日付にしつつ連泊の制限を満たすように収める
export const getValidatedCheckInOut = ({
  checkin,
  checkout,
}: {
  checkin: string | undefined
  checkout: string | undefined
}): { checkin: string; checkout: string } => {
  const defaultValues = { checkin: searchHotelFormDefaultData.checkin(), checkout: searchHotelFormDefaultData.checkout() }
  if (!checkin) return defaultValues

  const today = startOfDay(Date.now())
  const checkinDate = new Date(checkin)
  if (checkinDate.getTime() < today.getTime()) return defaultValues

  const format = 'yyyy-MM-dd'
  let checkoutDate = addDays(checkinDate, 1)
  if (checkout) checkoutDate = new Date(checkout)
  const diffDays = differenceInDays(checkoutDate, checkinDate)
  if (diffDays < 1)
    return { checkin: formatUtcDateStringInJST(checkinDate, format), checkout: formatUtcDateStringInJST(addDays(checkinDate, 1), format) }
  if (diffDays > maxNightsOfStay)
    return {
      checkin: formatUtcDateStringInJST(checkinDate, format),
      checkout: formatUtcDateStringInJST(addDays(checkinDate, maxNightsOfStay), format),
    }

  return { checkin: formatUtcDateStringInJST(checkinDate, format), checkout: formatUtcDateStringInJST(checkoutDate, format) }
}

export const mealsMaster: readonly { id: RateAmenitiesMealType; label: string }[] = [
  { id: 'breakfastOnly', label: '朝食のみ' },
  { id: 'dinnerOnly', label: '夕食のみ' },
  { id: 'breakfastAndDinner', label: '朝夕付き' },
  { id: 'halfBoard', label: 'Half board' },
  { id: 'fullBoard', label: 'Full board' },
  { id: 'allInclusive', label: 'All-inclusive' },
] as const
export const mealTypes: RateAmenitiesMealType[] = mealsMaster.map(m => m.id)
export const mealTypeLabel = (mealType: string) => mealsMasterWithAny.find(m => m.id === mealType)?.label
export const mealsMasterWithAny: readonly { id: RateAmenitiesMealType | 'any'; label: string; description?: string }[] = [
  { id: 'any', label: '指定なし' },
  ...mealsMaster,
]
export const formatMealLabels = (labels: string[], language: 'ja' | 'en' = 'ja'): string => {
  if (labels.length === 0) {
    switch (language) {
      case 'ja':
        return 'なし'
      case 'en':
        return 'Not Included'
    }
  }

  return Array.from(new Set(labels)).join(' & ')
}

export const hotelCategoriesMaster: readonly { id: SearchPropertyContentCategory; label: string }[] = [
  { id: 'hotel', label: 'ホテル' },
  { id: 'ryokan', label: '旅館' },
  { id: 'villa', label: 'ヴィラ' },
  { id: 'hostel', label: 'ホステル' },
  { id: 'apart', label: 'アパートメント' },
  { id: 'bedBreakfast', label: 'ベッド＆ブレックファスト' },
]
export const hotelCategories = hotelCategoriesMaster.map(m => m.id)

export const searchHotelStarRatings: readonly SearchPropertyContentRatings[] = ['s5', 's4', 's3'] as const

export const guestRatingsMaster: readonly { id: string; label: string }[] = [
  { id: 'all', label: 'すべて' },
  { id: '4.5', label: 'とても素晴らしい（4.5以上）' },
  { id: '4', label: 'とても良い（4以上）' },
  { id: '3.5', label: '良い（3.5以上）' },
] as const

export const hotelFacilityTypes: readonly SearchPropertyContentInputFacility[] = [
  'allTimeReception',
  'freeWiFi',
  'sauna',
  'fitness',
  'pool',
  'laundry',
  'restaurants',
  'meetingRoom',
  'multiLanguage',
  'parking',
  'spa',
  'petOk',
  'oceanView',
  'mountainView',
  'lakeView',
] as const
