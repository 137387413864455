import { Center, CenterProps, Spinner, SpinnerProps } from '@chakra-ui/react'

type Props = CenterProps & {
  size?: SpinnerProps['size']
}

export const Loading: React.FC<Props> = ({ size, ...props }) => {
  return (
    <Center {...props} py={6}>
      <Spinner color="blue.500" size={size} thickness={'3px'} />
    </Center>
  )
}

Loading.defaultProps = {
  size: 'lg',
}
