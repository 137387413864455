import { buildErrorMessage } from 'lib/errorHandler/buildErrorMessage'
import { processError } from 'lib/errorHandler/processError'
import { useCallback, useEffect } from 'react'
import { useCustomToast } from './useCustomToast'

export const useShowErrorMessageIfNeed = (error?: unknown) => {
  const toast = useCustomToast()

  const showErrorMessageIfNeed = useCallback((error: unknown) => {
    if (!error) return
    const message = buildErrorMessage(processError(error))
    toast('error', message)
  }, [])

  useEffect(() => showErrorMessageIfNeed(error), [error, showErrorMessageIfNeed])

  return { showErrorMessageIfNeed }
}
