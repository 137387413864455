import { Box, Heading, StyleProps } from '@chakra-ui/react'
import { FC, ReactNode, useMemo } from 'react'

type CustomHeadingProps = {
  children: ReactNode
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  decorationLeft?: boolean
  underline?: boolean
} & StyleProps

export const CustomHeading: FC<CustomHeadingProps> = ({ as, decorationLeft, underline, children, ...props }) => {
  const fontSize = useMemo(() => {
    switch (as) {
      case 'h1':
        return 42
      case 'h2':
        return 36
      case 'h3':
        return 28
      case 'h4':
        return 24
      case 'h5':
        return 20
      case 'h6':
        return 16
    }
  }, [as])

  const lineHeight = useMemo(() => {
    switch (as) {
      case 'h1':
        return '44px'
      case 'h2':
        return '44px'
      case 'h3':
        return '36px'
      case 'h4':
        return '30px'
      case 'h5':
        return '28px'
      case 'h6':
        return '20px'
    }
  }, [as])

  return (
    <Box position="relative">
      {decorationLeft && <Box position="absolute" top={0} left={0} w="8px" h="full" bgColor="secondary" rounded={'2px'} />}
      <Heading {...props} as={as} fontSize={fontSize} fontWeight="medium" lineHeight={lineHeight} pl={decorationLeft ? 4 : 'initial'}>
        {children}
      </Heading>
      {underline && decorationLeft && <Box h="2px" bgColor="secondary" rounded={1} />}
      {underline && !decorationLeft && <Box h={as === 'h3' ? '4px' : '2px'} bgColor="secondary" rounded={1} mt={as === 'h3' ? 1 : 0} />}
    </Box>
  )
}
